/*
 *  Custom Theme
 *
 *  Custom Theming for Angular Material
 *  For more information: https://material.angular.io/guide/theming
 */

@use "@angular/material" as mat;

/*
  *  Custom Font
  *
  *  Font: Avenir Next
  */

@font-face {
  font-family: "Avenir Next";
  src: local("Avenir-Next-Font"),
    url(./assets/fonts/Avenir-Next-Font.ttf) format("truetype");
}

/*
  *  Custom Typography
  *
  *  Include the common styles for Angular Material. We include this here so that you only
  *  have to load a single css file for Angular Material in your app.
  *  Be sure that you only ever include this mixin once!
  */

$avenir-typography: mat.define-typography-config(
  /*
      *  CSS class: .mat-h1 or .mat-headline
      *  Level name:	headline
      *  Native elements: <h1>
      */
    $headline:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 600,
      $font-size: 24px,
      $line-height: 32px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-h2 or .mat-title
      *  Level name:	title
      *  Native elements: <h2>
      */
    $title:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 600,
      $font-size: 20px,
      $line-height: 28px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-h3 or .mat-subheading-2
      *  Level name:	subheading-2
      *  Native elements: <h3>
      */
    $subheading-2:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 600,
      $font-size: 16px,
      $line-height: 24px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-h4 or .mat-subheading-1
      *  Level name:	subheading-1
      *  Native elements: <h4>
      */
    $subheading-1:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 600,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-body or .mat-body-1
      *  Level name:	body-1
      *  Native elements: Body text
      */
    $body-1:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 500,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-body-strong or .mat-body-2
      *  Level name:	body-2
      *  Native elements: None
      */
    $body-2:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 500,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-small or .mat-caption
      *  Level name:	caption
      *  Native elements: None
      */
    $caption:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 500,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-display-4
      *  Level name: display-4
      *  Native elements	None (add this in the <h5 class="mat-display-4">)
      */
    $display-4:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 600,
      $font-size: 10px,
      $line-height: 12px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-display-3
      *  Level name: display-3
      *  Native elements	None (add this in the <p class="mat-display-3"> and <a class="mat-display-3">)
      */
    $display-3:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 500,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-display-2
      *  Level name: display-2
      *  Native elements	None
      */
    $display-2:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 500,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-display-1
      *  Level name: display-1
      *  Native elements	None
      */
    $display-1:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 500,
      $font-size: 13px,
      $line-height: 20px,
      $letter-spacing: 0,
    ),
  /*
      *  CSS class: .mat-button
      *  Level name: button
      *  Native elements	button
      */
    $button:
    mat.define-typography-level(
      $font-family: "Avenir Next",
      $font-weight: 600,
      $font-size: 12px,
      $line-height: 20px,
      $letter-spacing: 0,
    )
);

/*
  * Apply the custom Typography
  */
@include mat.core($avenir-typography);

/*
  *  Custom Theme
  *
  *  Define the palettes for your theme using the Material Design palettes available in palette.scss
  *  (imported above). For each palette, you can optionally specify a default, lighter, and darker
  *  hue. Available color palettes: https://material.io/design/color/
  */

/*
  *  Palette Level: Primary
  *  Palette Name:  Turquoise
  *  Theme Mode:    Light
  */
$turquoise-palette: (
  100: #e6f3f5,
  200: #0ca8ba,
  300: #0b9aab,
  400: #0a8a99,
  700: #0a8a99,
  contrast: (
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    700: white,
  ),
);

$custom-primary: mat.define-palette($turquoise-palette, 300);

/*
  *  Palette Level: Accent
  *  Palette Name:  Orange
  *  Theme Mode:    Light
  */
$orange-palette: (
  100: #fff5e5,
  200: #faa21f,
  700: #faa21f,
  contrast: (
    100: #faa21f,
    200: #fff5e5,
    700: #fff5e5,
  ),
);

$custom-accent: mat.define-palette($orange-palette, 200);

/*
  *  Palette Level: Warn
  *  Palette Name:  Coral
  *  Theme Mode:    Light
  */
$coral-palette: (
  100: #ffefee,
  200: #fc7b74,
  300: #ed6861,
  400: #db605a,
  700: #db605a,
  contrast: (
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    700: white,
  ),
);

$custom-warn: mat.define-palette($coral-palette, 300);

/*
  *  Palette Level: None
  *  Palette Name:  Neutrals
  *  Theme Mode:    Light
  */
$light-neutrals-palette: (
  100: #ffffff,
  200: #faf9f9,
  300: #f2f1f1,
  400: #ebeaea,
  500: #d9d9d9,
  700: #d9d9d9,
  contrast: (
    100: #4b4b4b,
    200: #4b4b4b,
    300: #4b4b4b,
    400: #4b4b4b,
    500: #4b4b4b,
    700: #4b4b4b,
  ),
);

/*
  *  Palette Level: None
  *  Palette Name:  Neutrals
  *  Theme Mode:    Dark
  */
$dark-neutrals-palette: (
  100: #bababa,
  200: #999898,
  300: #4b4b4b,
  400: #262626,
  700: #262626,
  contrast: (
    100: white,
    200: white,
    300: white,
    400: #262626,
    700: #262626,
  ),
);

/*
  *  Create the theme object. A theme consists of configurations for individual
  *  theming systems such as "color" or "typography".
  */

$custom-theme: mat.define-light-theme(
  (
    color: (
      primary: $custom-primary,
      accent: $custom-primary,
      warn: $custom-warn,
    ),
  )
);

/*
  *  Include theme styles for core and each component used in your app.
  *  Alternatively, you can import and @include the theme mixins for each component
  *  that you are using.
  */

@include mat.all-component-themes($custom-theme);

/*
  *  Input form control
  *
  *  For more info https://material.angular.io/components/input/overview
  */
mat-label {
  @include mat.typography-level($avenir-typography, "display-4");
  color: mat.get-color-from-palette($dark-neutrals-palette, 300);
  text-transform: uppercase;
}

input.mat-input-element,
.mat-form-field-label {
  border: 1px solid #e7e7e7;
  padding: 14px 10px;
  @include mat.typography-level($avenir-typography, "body-1");
  color: mat.get-color-from-palette($dark-neutrals-palette, 300);
  line-height: 16px;
}

.mat-form-field-appearance-legacy {
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.mat-form-field-appearance-outline {
  &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-19px) scale(0.75);
    display: none !important;
  }

  &.mat-form-field-can-float.mat-form-field-should-float
    div.mat-form-field-outline-gap {
    border-top-color: inherit;
  }

  &.mat-form-field-disabled {
    .mat-form-field-outline {
      background: #f2f2f2;
    }
  }

  .mat-form-field-infix {
    padding: 12px 0;
    border-top: 5px solid transparent;
  }

  .mat-form-field-label {
    top: 25px;
  }

  div.mat-form-field-suffix {
    top: 5px;

    .mat-icon-button .mat-icon {
      color: mat.get-color-from-palette($dark-neutrals-palette, 300);
    }
  }

  .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
    padding: 0;
  }

  .mat-form-field-outline-thick {
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end {
      border-radius: 0 !important;
    }
  }

  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-radius: 0 !important;
  }
}

/*
  *  Select form control
  *
  *  For more info https://material.angular.io/components/select/overview
  */

mat-select {
  border: 1px solid #e7e7e7;
  padding: 12px 10px;

  .mat-select-value-text {
    color: mat.get-color-from-palette($dark-neutrals-palette, 300);
    font: 600 12px / 18px Avenir Next !important;
  }
}
.mat-select-disabled {
  background-color: rgba(0,0,0,0.38);
}
.mat-select-panel-wrap {
position: relative;
  top: -15px;

  .mat-select-panel {
    box-shadow: none;
    border: 1px solid #e7e7e7;
  }
}

/*
  *  Checkbox form control
  *
  *  For more info https://material.angular.io/components/checkbox/overview
  */

mat-checkbox {
  label {
    color: mat.get-color-from-palette($dark-neutrals-palette, 300);
    @include mat.typography-level($avenir-typography, "body-1");
  }
}

/*
  *  Button
  *
  *  For more info https://material.angular.io/components/button/overview
  */

button.mat-flat-button {
  &.mat-button,
  &.mat-icon-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    border-radius: 0;
    padding: 2px 16px;
    text-transform: uppercase;
  }

  &:hover:enabled {
    background-color: mat.get-color-from-palette($turquoise-palette, 200);
  }
  &:active:enabled {
    background-color: mat.get-color-from-palette($turquoise-palette, 400);
  }

  /* check mat stroked */
  &.ghost {
    background-color: mat.get-color-from-palette($light-neutrals-palette, 100);
    border: 1px solid #d9d9d9;

    &:hover:enabled {
      background-color: mat.get-color-from-palette(
        $light-neutrals-palette,
        200
      );
    }

    &:active:enabled {
      background-color: mat.get-color-from-palette(
        $light-neutrals-palette,
        300
      );
    }
  }
}

/*
  *  Toolbar
  *
  *  For more info https://material.angular.io/components/toolbar/overview
  */

.mat-toolbar {
  color: mat.get-color-from-palette($dark-neutrals-palette, 300);
  @include mat.typography-level($avenir-typography, "body-1");
}

/*
  *  Menu
  *
  *  For more info https://material.angular.io/components/menu/overview
  */

.mat-menu-panel {
  border: 1px solid #e7e7e7;
  box-shadow: none;

  .mat-menu-content .mat-menu-item {
    padding: 0 80px 0 20px;
  }
}

/*
  *  Snack Bar
  *
  *  For more info https://material.angular.io/components/snack-bar/overview
  */

.mat-snack-bar-container {
  @include mat.typography-level($avenir-typography, "subheading-1");
  color: mat.get-color-from-palette($dark-neutrals-palette, 300);
  background: mat.get-color-from-palette($coral-palette, 100);
  border: 1px solid mat.get-color-from-palette($coral-palette, 100);
  box-shadow: none;
}

/*
  *  Link
  *
  *  For more info: Tradebyte IW Design System
  */

a {
  color: mat.get-color-from-palette($custom-primary, default);
  font: 500 13px / 20px Avenir Next !important;
  letter-spacing: normal;
}

.link-box {
  display: flex;
  justify-content: space-between;
  background-color: mat.get-color-from-palette($light-neutrals-palette, 100);
  border: 1px solid mat.get-color-from-palette($light-neutrals-palette, 400);
  text-decoration: none;
  padding: 9px 0 9px 12px;
  align-items: center;

  span:first-of-type {
    text-decoration: underline;
  }

  .icon {
    padding: 0 6px;
  }

  &:hover {
    background-color: mat.get-color-from-palette($light-neutrals-palette, 200);

    span:first-of-type {
      text-decoration: none;
    }
  }

  span.material-icons {
    margin-top: -3px;
  }
}

/*
  *  Dialog
  *
  *  For more info https://material.angular.io/components/dialog/overview
  */

mat-dialog-container.mat-dialog-container {
  min-width: 700px;
  border-radius: 0;
}

/*
  *  Radio
  *
  *  For more info https://material.angular.io/components/radio/overview
  */
mat-radio-button {
  span.mat-radio-outer-circle {
    border-width: 1px;
    border-color: #d9d9d9;
  }
  label {
    margin: 4px 12px;
  }
}

body {
  margin: 0 10% 0 10%;
}

